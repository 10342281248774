if (import.meta.env.MODE !== 'development') {
    import('vite/modulepreload-polyfill');
}

import './modules/_sentry';
import './modules/_confetti';

// your app js here..
// import './modules/_posthog';
import './modules/_console-welcome';
